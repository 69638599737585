import { Noticia, Sort } from "@/types";

export const sortNewsByPublicationDate = (
  news: Noticia[],
  sort: Sort = "desc"
) => {
  return sort === "asc"
    ? news.sort(
        (newItem1: Noticia, newItem2: Noticia) =>
          newItem1.publicationDate.seconds - newItem2.publicationDate.seconds
      )
    : news.sort(
        (newItem1: Noticia, newItem2: Noticia) =>
          newItem2.publicationDate.seconds - newItem1.publicationDate.seconds
      );
};
